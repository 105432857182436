// if: lines of code > 300
// then: breakdown this file and move to src/features/Listings/utils folder

import constants from "constants/constants";
import {
  PAYMENT_PLAN_TYPE,
  PRICING_MODE_KEYS,
  schedule_types_ids,
  webinarMaxSpot,
} from "constants/schedule";
import { capitalizeFirstLetter } from "features/Common/modules/DataTypes/utils/strings";
import { isTimeExpired } from "features/Crm/modules/AddCustomerModal/utils/AddCustomer.utils";
import { parsePhoneNumber } from "utils/Utils";
import { is_empty } from "utils/validations";
// if: lines of code > 300

/**
 * Checks if the given slot is available.
 * @param {object} slot - The slot to check.
 * @param {boolean} isWebinar - Indicates whether the slot is for a webinar.
 * @returns {boolean} Returns true if the slot is available, otherwise false.
 */
const isSlotAvailable = ({ slot, isWebinar }) => {
  const { start_time } = slot;

  const isDateExpired = isTimeExpired(start_time, "days");

  if (isDateExpired) return false;

  const isSlotTimeExpired = isTimeExpired(start_time, "hours");

  if (isSlotTimeExpired) return false;

  const isAllSpotsFilled = isWebinar
    ? slot.spots_filled > webinarMaxSpot
    : slot.spots_filled > 0;

  return !isAllSpotsFilled;
};

/**
 * Retrieves the available slots from the given listing data.
 * @param {object} listingData - The listing data containing slots.
 * @returns {array} Returns an array of available slots.
 */
export const getAvailableSlots = (listingData) => {
  if (is_empty(listingData)) return [];

  const { type: listingType, slots: listingSlots = [] } = listingData;
  const isWebinar = listingType === constants.scheduleTypesId.webinar;

  return listingSlots.filter((slot) => isSlotAvailable({ slot, isWebinar }));
};

/**
 * navigates to whatsapp page using a phone number from a record.
 *
 * @function onConnectWhatsapp
 * @param {Object} params - The parameters for connecting to WhatsApp.
 * @param {Object} params.record - The record containing the phone number information.
 * @param {string} params.countryCodeKey - The key used to extract the country code from the record.
 * @param {string} params.source - The key used to extract the phone number from the record.
 */

export const onConnectWhatsapp = ({ record, countryCodeKey, source }) => {
  const phone_number = parsePhoneNumber(record, {
    countryCodeKey,
    phoneNumberKey: source,
  });
  if (phone_number) {
    window.open(
      `https://api.whatsapp.com/send?phone=${phone_number.substring(1)}`,
      "_blank"
    );
  }
};
/**
 * Converts UTM information from a record into a single string for CSV.
 *
 * @param {Object} params - The parameters for extracting UTM information.
 * @param {Object} params.record - The record containing UTM information.
 * @returns {string} - The UTM information as a single string, or '-' if no information is available.
 */

export const getUtmInfoString = ({ record = {} }) => {
  if (!record || !record.utm_info) return "-";

  const filteredEntries = Object.entries(record.utm_info).filter(
    ([, value]) => !is_empty(value)
  );

  if (is_empty(filteredEntries)) {
    return "-";
  }

  const utmString = filteredEntries
    .map(([key, value]) => {
      const label = `${capitalizeFirstLetter(key)}: `;
      return `${label}${value}`;
    })
    .join(", ");

  return utmString;
};

export const isScheduleTypeListing = (type) => {
  const listing_type_including_schedules = [
    schedule_types_ids.workshop,
    schedule_types_ids.webinar,
    schedule_types_ids.one_on_one_appointment,
    schedule_types_ids.one_on_one_class,
    schedule_types_ids.group_class,
    schedule_types_ids.rolling_class,
  ];
  return listing_type_including_schedules.includes(type);
};
/**
 *
 * @param {*} offering offering object containing type and  pricing_mode key
 */

export const isGroupClassOneTime = (offering) => {
  return (
    offering?.type === schedule_types_ids.group_class &&
    offering?.pricing_mode === PRICING_MODE_KEYS.oneTime &&
    offering?.payment_plan_type !== PAYMENT_PLAN_TYPE.SUBSCRIPTIONS
  );
};

export const isListingPlanTypeSubscription = (offering) => {
  return offering?.payment_plan_type === PAYMENT_PLAN_TYPE.SUBSCRIPTIONS;
};

export const isListingWhatsappCommunity = ({ type }) => {
  return type === schedule_types_ids.whatsapp;
};

export const isListingMerchandise = ({ type }) => {
  return type === schedule_types_ids.merchandise;
};
